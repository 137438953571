@value colors: "../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-text-subtle from colors;

.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;
}

.error h1 {
    font-size: 150px;
    margin-bottom: 16px;
    margin-top: 0;
    background: -webkit-linear-gradient(color-purple, color-purple-dark);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subtitle {
    font-size: 36px;
    margin-top: 0;
    margin-bottom: 0;
}

.description {
    font-size: 18px;
    color: color-text-subtle;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.row button + button {
    margin-left: 16px;
}

@media screen and (max-width: 768px) {
    .error {
        padding: 16px;
        box-sizing: border-box;
    }

    .error h1 {
        font-size: 100px;
    }

    .subtitle {
        font-size: 24px;
        text-align: center;
    }

    .description {
        font-size: 16px;
        text-align: center;
    }

    .row button {
        width: 100%;
        margin: 8px 0;
    }

    .row button + button {
        margin-left: 0;
    }
}
